defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/GameClients/VikingLottoClassicClient', [
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/Classic',
  'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
], function (Params, Event, NumberGamesClassic, VikingLottoGame, VikingLottoInfo) {

  // Client:
  var GameClient = function (m, settings, property) {

    // Components:
    var root = {
      controller: function () {
        this.settings = settings;
        this.property = property;

        // Game:
        var gameInstanceId = Params.extract('gameInstanceId');

        if (gameInstanceId) {
          var game = VikingLottoGame.get(gameInstanceId);

          if (!game) {
            gameInstanceId = undefined;
          }
        }

        // Variables:
        this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
        this.game = m.prop(game ? game : VikingLottoGame.new({ playType: 'Classic' }));

        // Replace state:
        if (!gameInstanceId || gameInstanceId !== this.game().id()) {
          Params.set('gameInstanceId=' + this.game().id());
        }

        // Tracking - Push productDetails event to dataLayer
        this.game().trackingProductDetails();

        Event.subscribe('vikinglotto:classic:createNewRow', () => this.property('callToCreateNewRow', true));
        Event.subscribe('numbersPicker:newGameInstanceGenerated', ({ id }) => {
          if (this.gameInstanceId() !== id) {
            Params.set('gameInstanceId=' + id);
          }
        });
      },

      view: function (controller) {
        return (<NumberGamesClassic
          rootSettings={settings}
          rootProperty={property}
          gameInstance={controller.game}
          gameModel={VikingLottoGame}
          gameName={'vikinglotto'}
          rowsOptions={VikingLottoInfo.getClassicRowOptions()}
          defaultRowCount={VikingLottoInfo.getClassicRowDefaultRowCount()}
        />);
      }
    };

    return root;
  };

  // Public functions:
  return GameClient;

});
