defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/VikingLottoConfirm',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Confirm',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoUtils',
    'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/ConfirmBar',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoClassic/DeleteOverlay',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/ConfirmOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Resize',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ReceiveWinnerMessage',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer',
    'Shared/Framework/Ensighten/Scripts/Ensighten'

  ],
  function (Component, AccountIntegration, Confirm, Overlay, ErrorOverlay, VikingLottoDictionary, VikingLottoInfo, VikingLottoUtils, VikingLottoGame, ConfirmBar, DeleteOverlay, ConfirmOverlay, Resize, ReceiveWinnerMessage, DataLayer, Ensighten) {

    // Component:
    Component('vikinglotto-confirm', [AccountIntegration, VikingLottoDictionary, VikingLottoInfo], function (m, route, settings, property) {

      // Variables:
      var id = VikingLottoUtils.getQueryParam('gameInstanceId');
      var rebuyCouponId = VikingLottoUtils.getQueryParam('rebuyCouponId');
      var game;

      // Missing id:
      if (!id && !rebuyCouponId) {
        return;
      }

      if (rebuyCouponId && settings.returnToCompletedGamesOverviewLink) {
        var page = VikingLottoUtils.getQueryParam('page') || 1;
        var period = VikingLottoUtils.getQueryParam('period') || 365;
        var offset = VikingLottoUtils.getQueryParam('offset') || 1;
        var type = VikingLottoUtils.getQueryParam('type') || 'afsluttede';
        var channel = VikingLottoUtils.getQueryParam('channel') || 'alle-kanaler';
        game = VikingLottoGame.new({
          rebuyCouponId: rebuyCouponId,
          startUrl: settings.returnToCompletedGamesOverviewLink + '#/' + page + '/' + period + '/' + offset + '/' + type + '/' + channel
        });
      } else {
        game = VikingLottoGame.get(id);
      }

      // Missing game:
      if (!game) {
        console.error('VikingLottoConfirm: Game not found, id: ' + id);

        return;
      }

      // Components:
      var root = {
        controller: function () {

          // Variables:
          var self = this;

          this.askForDeposit = m.prop(false);
          this.d = VikingLottoDictionary.get;
          this.game = m.prop(game);
          this.gradientHeight = m.prop();
          this.loading = m.prop(false);
          this.property = property;
          this.openDraw = m.prop(VikingLottoInfo.data().openDraw || null);
          this.rowLimit = m.prop(2);
          this.rowHeight = m.prop();
          this.rows = m.prop();
          this.notifyPrizeEmail = m.prop();
          this.notifyPrizeSms = m.prop();
          this.ready = m.prop(false);
          this.rebuyCouponNotFound = m.prop(false);
          this.showPrizeNotificationSection = m.prop(true);
          this.redirectWhenNoOpenDrawLink = m.prop(settings.redirectWhenNoOpenDrawLink);
          this.redirectWhenNoOpenDrawLinkActivate = m.prop(settings.redirectWhenNoOpenDrawLinkActivate);

          // Functions:
          this.confirmErrorOverlay = function (errorTexts) {
            return new Overlay(ErrorOverlay('vikinglotto-classic-game-overlay', errorTexts));
          }.bind(this);

          this.deleteOverlay = function () {
            return new Overlay(DeleteOverlay(this.d));
          }.bind(this);

          this.drawDate = function () {
            var s = this.d('ConfirmStep/DrawDateTo');

            return m.trust(this.game().drawDateHtml(s));
          }.bind(this);

          this.back = function () {
            location.href = this.game().cancelUrl();
          }.bind(this);

          this.confirm = function () {
            var self = this;

            if (!this.loading()) {
              this.loading(true);

              this.game().purchase({
                drawId: (self.openDraw() && self.openDraw().id) || null,
                notifyPrizeEmail: self.notifyPrizeEmail(),
                notifyPrizeSms: self.notifyPrizeSms()
              }).then(function () {
                self.loading(false);

                m.redraw();

                var receiptUrl = self.game().receiptUrl();

                try {
                  window.history.replaceState({}, self.d('ConfirmStep/ReceiptTitle'), receiptUrl);
                } catch (error) {
                  // Nothing to handle here, just proceed to location.href
                }

                location.href = receiptUrl;
              }, function (data) {
                self.loading(false);

                m.redraw();

                Confirm.handlePurchaseError(self.game, (data && data.errorMessage) || null, self.showError);
              });
            }

            m.redraw(); // for applying "pending" state in view

            return false;
          }.bind(this);

          // Deposit flow:
          this.openDeposit = function () {
            AccountIntegration.openDeposit(this.game().totalPrice(), function () {
              location.href = this.game().cancelUrl();
            });
          }.bind(this);

          // Delete row from game, and in view
          this.deleteRow = function (rowNumber) {
            var allRows = this.game().getRows(false);
            var index = 0;
            var rows = this.game().getRowsSummary();
            var selectedNumbers = rows[rowNumber - 1].numbers;

            for (var i = 0; i < allRows.length; i++) {
              var row = allRows[i];
              var numbers = row.numbers.map(function (numbers) {
                return numbers.number;
              });

              var match = numbers.length !== 0;

              for (var j = 0; j < numbers.length; j++) {
                if (numbers[j] !== selectedNumbers[j]) {
                  match = false;

                  break;
                }
              }

              if (match) {
                index = i;

                break;
              }
            }

            rowNumber = index + 1;

            this.property('animate', 'remove');

            if (typeof this.deleteOverlay !== 'undefined') {
              this.property('deleteRow', rowNumber);

              var self = this;

              this.deleteOverlay().show(function () {
                game.removeRow(rowNumber);

                if (!game.isGameValid() && self.back) {
                  game.addRow();

                  game.save();

                  self.back();
                }
              });
            }
          }.bind(this);

          // Show all rows:
          this.showAllRows = function () {
            this.rowLimit(this.rows().length + 1);
          }.bind(this);

          // Is there any more rows in the current game:
          this.maxRowsReached = function () {
            if (this.rowLimit() > 2 || this.rows().length <= 2) {
              return true;
            }
          }.bind(this);

          this.getContainerStyle = function () {
            if (!this.rowHeight()) {
              return 'max-height: auto';
            }

            if (this.rows().length > 2) {
              var height = this.rowHeight() * Math.min(this.rowLimit() - 1, this.rows().length);

              if (!this.maxRowsReached()) {
                height += this.gradientHeight();
              }

              return 'max-height: ' + height + 'px';
            } else {
              return 'max-height: auto';
            }
          }.bind(this);

          this.showError = function (errorType) {
            console.debug('errorType', errorType);

            var error = Confirm.getPurchaseError(errorType);

            if (this.redirectWhenNoOpenDrawLinkActivate() && errorType === 'NUMBERGAMES.NO_OPEN_DRAW') {
              window.location.href = this.redirectWhenNoOpenDrawLink();
            } else {
              var texts = {
                header: this.d('PurchaseErrors/' + error + 'Header'),
                body: this.d('PurchaseErrors/' + error + 'Body'),
                dismiss: this.d('PurchaseErrors/' + error + 'Dismiss')
              };

              this.confirmErrorOverlay(texts).show();
            }
          }.bind(this);

          // Game status class:
          this.gameStatusClass = function () {
            return 'status-' + this.game().status();
          }.bind(this);

          // Context:
          this.game().ready().promise.then(function () {
            Confirm.checkAuthAndBalance(self.game).then(function () {
              AccountIntegration.getUserData().then(function (customerInfo) {
                self.notifyPrizeEmail(customerInfo.notifyPrizeEMail || false);
                self.notifyPrizeSms(customerInfo.notifyPrizeSms || false);

                // Show if notification section if not already notified on either email or sms
                self.showPrizeNotificationSection(!self.notifyPrizeEmail() && !self.notifyPrizeSms());

                self.rows(self.game().getRowsSummary());

                self.ready(true);

                m.redraw();
              });
            }, function (error) {
              if (error === 'ASK_FOR_DEPOSIT') {
                self.askForDeposit(true);

                // Add ensighten virtualPage event:
                if (self.game) {
                  Ensighten.pushVirtualPage('tank_op', DataLayer.categoryName(self.game().playType()) + '/tank_op', 'notEnoughCreditPage');
                }

                self.ready(true);

                m.redraw();
              }
            });
          }, function () {
            self.rebuyCouponNotFound(true);

            self.ready(true);

            m.redraw();
          }.bind(this));

          // Push addToCart event to dataLayer:
          this.game().ready().promise.then(function () {
            game.trackingAddToCart();
          });

          this.toggleCollapsible = function (ev) {
            ev.currentTarget.parentElement.classList.toggle('confirm-page__collapsible--show');
          };

          this.game().ready().promise.then(function () {
            if (game.rowPriceChanged() || game.reducedWeeks()) {
              var bodyText = this.d('ConfirmPage/PriceChange/Notice');

              if (game.reducedWeeks()) {
                bodyText = this.d('ConfirmPage/PriceChange/NoticeReducedWeeks', { ORIGINALDRAWS: game.originalNumberOfDraws(), DRAWS: game.numberOfDraws() });
              }

              new Overlay(ConfirmOverlay('vikinglotto-classic-game-overlay', {
                header: this.d('ConfirmPage/PriceChange/OverlayHeader'),
                body: bodyText,
                confirm: this.d('ConfirmPage/PriceChange/OverlayButton')
              })).show();
            }
          }.bind(this));
        },

        view: function (controller) {

          // Ready:
          if (!controller.ready()) {
            return;
          }

          // Rebuy coupon not found:
          if (controller.rebuyCouponNotFound()) {
            controller.confirm = null;
            return m('div', { class: 'vikinglotto-confirm ' + controller.gameStatusClass() }, [
              m('div', { class: 'vikinglotto-section bg-white confirm-bar-container' }, [
                m('div', { class: 'vikinglotto-content-wrapper vikinglotto-row-display' }, [
                  m('div', { class: 'vikinglotto-confirm-page-header' }, [
                    m('h3', { class: 'confirm-headline' }, controller.d('ConfirmStep/RebuyCouponNotFoundTitle')),
                    m('p', { class: 'alt-text' }, controller.d('ConfirmStep/RebuyCouponNotFoundDescription'))
                  ]),
                  ConfirmBar(controller)
                ])
              ])
            ]);
          }

          // Deposit flow:
          if (controller.askForDeposit()) {
            return m('div', { class: 'vikinglotto-confirm ' + controller.gameStatusClass() }, [
              m('div', { class: 'vikinglotto-section bg-white confirm-bar-container' }, [
                m('div', { class: 'vikinglotto-content-wrapper vikinglotto-row-display' }, [
                  m('div', { class: 'vikinglotto-confirm-page-header' }, [
                    m('h3', { class: 'confirm-headline' }, controller.d('ConfirmStep/DepositHeader')),
                    m('p', { class: 'alt-text' }, controller.d('ConfirmStep/DepositText')),
                    m('a', { class: 'btn cta cta-deposit', onclick: controller.openDeposit }, controller.d('ConfirmStep/DepositButton'))
                  ])
                ])
              ])
            ]);
          }

          // Variables:
          var rows = game.getRowsSummary();
          var rowsJokerSaturday = game.getJokerRowsSummary('Saturday');
          var rowsJokerWednesday = game.getJokerRowsSummary('Wednesday');
          var gameDrawDates = game.drawDates();

          // Return:
          return m('.confirm-page__content', {
            class: controller.gameStatusClass(),
            'data-uitest-id': 'Confirm__content'
          }, [
            // title.
            m('.confirm-page__title', m.trust(controller.d('ConfirmPage/Title', {
              playType: controller.d('PlayTypes/VikingLotto' + game.playType()),
              system: game.playType() === 'System' ? game.systemName() : ''
            }))),

            // draw dates.
            gameDrawDates.length > 0 ? m('.confirm-page__collapsible.confirm-page__collapsible--can-open', {
              'data-uitest-id': 'Confirm__collapsible--draw-dates'
            }, [
              m('.confirm-page__collapsible-top', {
                onclick: controller.toggleCollapsible,
                'data-uitest-id': 'Confirm__collapsible--draw-dates-toggle'
              }, [
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon',
                    m('svg.confirm-page__collapsible-icon-draw', m('use', {
                      href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#vikinglotto-draw'
                    }))
                  ),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableDraw')),
                  m('.confirm-page__collapsible-top-row-text', {
                    'data-uitest-id': 'Confirm__collapsible--draw-dates-date'
                  }, game.drawDateHtmlShort()),
                ])
              ]),
              m('.confirm-page__collapsible-bottom', [
                m('.confirm-page__table', [
                  gameDrawDates.map(function (date, i) {
                    return m('.confirm-page__table-row', {
                      class: date.hasJoker ? 'confirm-page__table-row--has-joker' : ''
                    }, [
                      m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                      m('.confirm-page__table-cell', {
                        'data-uitest-id': 'Confirm__collapsible--draw-dates-date'
                      }, VikingLottoUtils.getLongDate(date.draw))
                    ]);
                  })
                ])
              ])
            ]) : null,

            // rows.
            rows.length > 0 ? m('.confirm-page__collapsible.confirm-page__collapsible--can-open', {
              'data-uitest-id': 'Confirm__collapsible--rows'
            }, [
              m('.confirm-page__collapsible-top', {
                onclick: controller.toggleCollapsible,
                'data-uitest-id': 'Confirm__collapsible--rows-toggle'
              }, [
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon',
                    m('svg.confirm-page__collapsible-icon-rows', m('use', {
                      href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#vikinglotto-rows'
                    }))
                  ),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableRows')),
                  m('.confirm-page__collapsible-top-row-text', {
                    'data-uitest-id': 'Confirm__collapsible--rows-rows'
                  }, rows.length),
                ])
              ]),
              m('.confirm-page__collapsible-bottom', [
                game.playType() === 'System'
                  ? [
                    m('.confirm-page__table-header', m.trust(controller.d('ConfirmPage/TableChosenSystemNumbers')) + ':'),
                    m('.confirm-page__table', [
                      m('.confirm-page__table-row',
                        m('.confirm-page__table-cell.confirm-page__table-cell--center', { 'data-uitest-id': 'Confirm__collapsible--rows-row' },
                          game.rows()[0].numbers.map(function (val) {
                            return val.number;
                          }).join(', ') + (game.rows()[0].vikingNumber ? ' + ' + game.rows()[0].vikingNumber.number : '')
                        )
                      )
                    ])
                  ]
                  : m('.confirm-page__table', [
                    rows.map(function (val, i) {
                      return m('.confirm-page__table-row', [
                        m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                        m('.confirm-page__table-cell', {
                          'data-uitest-id': 'Confirm__collapsible--rows-row'
                        }, val.numbers.join(', ') + ' + ' + val.vikingNumber),

                        game.playType() === 'Classic' ?
                          m('.confirm-page__table-cell-icon-delete',
                            {
                              onclick: controller.deleteRow.bind(controller, i + 1),
                              'data-uitest-id': 'Confirm__collapsible--rows-classic-row-delete'
                            },
                            m('svg', m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' }))
                          ) : null,
                      ]);
                    })
                  ]),
              ])
            ]) : null,

            // jokers.
            rowsJokerWednesday.length > 0 || rowsJokerSaturday.length > 0 ? m('.confirm-page__collapsible.confirm-page__collapsible--can-open', {
              'data-uitest-id': 'Confirm__collapsible--jokers'
            }, [
              m('.confirm-page__collapsible-top', {
                onclick: controller.toggleCollapsible,
                'data-uitest-id': 'Confirm__collapsible--jokers-toggle'
              }, [
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon',
                    m('svg.confirm-page__collapsible-icon-joker', m('use', {
                      href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#vikinglotto-joker'
                    }))
                  ),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableJoker')),
                  m('.confirm-page__collapsible-top-row-text',
                    {
                      'data-uitest-id': 'Confirm__collapsible--jokers-days'
                    }, rowsJokerWednesday.length > 0 && rowsJokerSaturday.length > 0
                      ? controller.d('ConfirmPage/TableJokerWednesdayAndSaturday')
                      : rowsJokerWednesday.length > 0 ? controller.d('ConfirmPage/TableJokerWednesday') : controller.d('ConfirmPage/TableJokerSaturday')
                  ),
                ])
              ]),
              m('.confirm-page__collapsible-bottom', [
                rowsJokerWednesday.length > 0 ? [
                  rowsJokerSaturday.length > 0 ? m('.confirm-page__table-header', m.trust(controller.d('ConfirmPage/TableJokerWednesday')) + ':') : '',
                  m('.confirm-page__table', [
                    rowsJokerWednesday.map(function (val, i) {
                      return m('.confirm-page__table-row', [
                        m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                        m('.confirm-page__table-cell', {
                          'data-uitest-id': 'Confirm__collapsible--jokers-wednesday-row'
                        }, val.numbers.join(', '))
                      ]);
                    }),
                  ]),
                ] : null,

                rowsJokerSaturday.length > 0 ? [
                  rowsJokerWednesday.length > 0 ? m('.confirm-page__table-header', m.trust(controller.d('ConfirmPage/TableJokerSaturday')) + ':') : '',
                  m('.confirm-page__table', [
                    rowsJokerSaturday.map(function (val, i) {
                      return m('.confirm-page__table-row', [
                        m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                        m('.confirm-page__table-cell', {
                          'data-uitest-id': 'Confirm__collapsible--jokers-saturday-row'
                        }, val.numbers.join(', '))
                      ]);
                    }),
                  ])
                ] : null

              ])
            ]) : null,

            // weeks.
            game.numberOfDraws()
              ? m('.confirm-page__collapsible', {
                'data-uitest-id': 'Confirm__collapsible--weeks'
              },
              m('.confirm-page__collapsible-top',
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon', m('svg.confirm-page__collapsible-icon-calendar', m('use', {
                    href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#vikinglotto-calendar'
                  }))),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableWeeks')),
                  m('.confirm-page__collapsible-top-row-text', {
                    'data-uitest-id': 'Confirm__collapsible--weeks-weeks'
                  }, game.reducedWeeks() ?
                    [
                      m('span.confirm-page__crossed', game.originalNumberOfDraws()),
                      m('span', ' 🠊 '),
                      m('span', game.numberOfDraws()),
                    ] : game.numberOfDraws()),
                ])
              )
              ) : null,

            // total.
            game.totalPrice() ? m('.confirm-page__total', {
              'data-uitest-id': 'Confirm__collapsible--total'
            }, [
              m('.confirm-page__total-label', controller.d('ConfirmPage/TableTotal')),
              m('.confirm-page__total-amount', {
                'data-uitest-id': 'Confirm__collapsible--total-amount'
              }, game.reducedWeeks() || game.rowPriceChanged() ?
                [
                  m('span.confirm-page__crossed', VikingLottoUtils.formatCurrency(game.originalPrice())),
                  m('span', ' 🠊 '),
                  m('span', VikingLottoUtils.formatCurrency(game.totalPrice())),
                ] : VikingLottoUtils.formatCurrency(game.totalPrice())),
            ]) : null,

            game.reducedWeeks() ?
              m('.confirm-page__info-message', controller.d('ConfirmPage/PriceChange/NoticeReducedWeeks', { ORIGINALDRAWS: game.originalNumberOfDraws(), DRAWS: game.numberOfDraws() }))
              : null,

            game.rowPriceChanged() && !game.reducedWeeks() ?
              m('.confirm-page__info-message', controller.d('ConfirmPage/PriceChange/Notice'))
              : null,

            // terms and conditions.
            m('.confirm-page__terms', {
              'data-uitest-id': 'Confirm__terms'
            }, [
              controller.d('ConfirmPage/GameVendor')
                ? controller.d('ConfirmPage/GameVendor')
                : null,

              controller.d('ConfirmPage/GameVendor') && controller.d('ConfirmPage/TermsLink') && controller.d('ConfirmPage/ViewTerms')
                ? m('span', ' - ') : '',

              controller.d('ConfirmPage/TermsLink') && controller.d('ConfirmPage/ViewTerms')
                ? m('a', {
                  href: controller.d('ConfirmPage/TermsLink')
                }, controller.d('ConfirmPage/ViewTerms'))
                : null
            ]),

            // show price notification.
            controller.showPrizeNotificationSection() ? ReceiveWinnerMessage(controller, 'vikinglotto', '/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#' + settings.iconUrl) : '',

            // confirm bar.
            ConfirmBar(controller),
          ]);
        }
      };

      // Setup routes:
      route('/', root);

    });

  });
