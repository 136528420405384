defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/GameClients/VikingLottoLuckyClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Lucky/Lucky',
    'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
  ],
  function (Params, NumberGamesLucky, VikingLottoGame, VikingLottoInfo) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Components:
      var root = {
        controller: function () {
          this.settings = settings;
          this.property = property;

          // Variables:
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(VikingLottoGame.setupGame({ gameInstanceId: this.gameInstanceId(), playType: 'Lucky', rowsToGenerate: VikingLottoInfo.getLuckyDefaultRowCount() }));

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          // Tracking - Push productDetails event to dataLayer
          this.game().trackingProductDetails();

        },

        view: function (controller) {
          return (<NumberGamesLucky
            rootSettings={settings}
            rootProperty={property}
            gameInstance={controller.game}
            gameModel={VikingLottoGame}
            gameName={'vikinglotto'}
            rowsOptions={settings.luckyRowOptions || VikingLottoInfo.getLuckyRowOptions()}
            defaultRowCount={VikingLottoInfo.getLuckyDefaultRowCount()}
          />);
        }
      };

      return root;
    };

    // Public functions:
    return GameClient;

  });
