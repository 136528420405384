defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/GameClients/VikingLottoSystemClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/System',
    'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
  ],
  function (Params, NumberGamesSystem, VikingLottoGame) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Components:
      var root = {
        controller: function () {
          this.settings = settings;
          this.property = property;

          // Variables:
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(VikingLottoGame.setupGame({ gameInstanceId: this.gameInstanceId(), playType: 'System' }));

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          this.game().trackingProductDetails();

        },

        view: function (controller) {
          return (<NumberGamesSystem
            rootSettings={settings}
            rootProperty={property}
            gameInstance={controller.game}
            gameModel={VikingLottoGame}
            gameName={'vikinglotto'}
            systemsTypes={['M', 'R', 'C']}
          />);
        }
      };

      return root;
    };

    // Public functions:
    return GameClient;

  });
